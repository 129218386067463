<template>
  <v-row class="home-header-container" :style="[{height: getHeaderHeight()}]">
    <v-col cols="12" class="pa-0">
      <v-carousel :height="getHeaderHeight()" hide-delimiters :show-arrows="false">
        <v-carousel-item
          v-for="(picture,i) in pictures"
          :key="i"
          :src="picture"
        />
      </v-carousel>
      <div class="header-title-container">
        <h1>{{ $t("home.header.title.white") }}
          <span>{{ $t("home.header.title.primary") }}</span>
        </h1>
        <h2>{{ $t("home.header.location") }}</h2>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'home-header',
    data() {
      return {
        pictures: [
          'https://ebikecup.com/images/headers/EBCUP_Headerbild_01.jpg',
        ],
        difference: undefined,
        result: false,
      };
    },
    methods: {
      getHeaderHeight() {
        let height = '600px';
        if (this.$vuetify.breakpoint.smAndDown) {
          height = '450px';
        } else if (this.$vuetify.breakpoint.mdOnly) {
          height = '500px';
        }
        return height;
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .home-header-container {
    position: relative;
    z-index:1;
    width: 100%;
    margin: 0 !important;
    .header-title-container {
      position: absolute;
      bottom: 50px;
      left: 200px;
      z-index: 2;
      color: white;
      font-size: 35px;
      font-style: italic;
      text-shadow: 2px 2px 4px #000000;
      @include MQ(XS) {
        left: 10px;
        bottom: 25px;
      }
      @include MQ(S) {
        left: 50px;
        bottom: 25px;
      }
      @include MQ(M) {
        left: 50px;
        bottom: 25px;
      }
      @include MQ(L) {
        left: 150px;
        bottom: 35px;
      }
      h1 {
        font-style: italic;
        color: white;
        font-size: 72px;
        line-height: 72px;
        text-align: left;
        font-weight: 600;
        span {
          color: $primary;
        }
        @include MQ(XS) {
          font-size: 38px;
          line-height: 38px;
          margin-bottom: 10px;
        }
        @include MQ(S) {
          font-size: 48px;
          line-height: 48px;
          margin-bottom: 10px;
        }
        @include MQ(M) {
          font-size: 48px;
          line-height: 48px;
        }
        @include MQ(L) {
          font-size: 62px;
          line-height: 62px;
        }
      }
      h2 {
        font-weight: 400;
        color: white;
        font-size: 46px;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        @include MQ(XS) {
          font-size: 22px;
          line-height: 30px;
        }
        @include MQ(S) {
          font-size: 30px;
          line-height: 32px;
        }
        @include MQ(M) {
          font-size: 30px;
          line-height: 32px;
        }
        @include MQ(L) {
          font-size: 40px;
          line-height: 42px;
        }
      }
    }
  }
</style>
