<template>
  <div class="home-info-bar-container">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="ma-0">
      <v-col cols="3" md="4" lg="3" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3">
          <img class="info-bar-background" alt="E-Bike WM"
               v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_ebikewm2020_01.jpg'">
          <v-col cols="12" style="height: 100%">
            <v-row style="z-index: 1; position: relative;">
              <v-col cols="5" class="pa-0"/>
              <v-col cols="7" class="pa-0" style="text-align: left;">
                <div style="height: 65px;padding-right: 5px;padding-top: 10px">
                  <h4 style="color: white; font-weight: 600;line-height: 16px;">{{ $t("home.infoBar.wm.title") }}</h4>
                  <div style="color: white;">{{ $t("home.infoBar.wm.subtitle") }}</div>
                </div>
                <v-btn outlined color="primary" @click="openWM()">
                  {{ $t("home.infoBar.news.discoverNow") }}
                  <v-icon>{{icons.right}}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-col>
      <v-col cols="6" md="4" lg="6" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3" @click="openHoheSalve()">
          <img class="info-bar-background" alt="E-Bike WM"
               v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_01.jpg'">
          <v-row class="info-bar-title-container">
            <v-col cols="6" md="8" class="align-self-center">
              {{$t('home.infoBar.hoheSalve')}}
            </v-col>
            <v-col cols="6" md="4" class="text-center">
              <v-img
                src="https://ebikecup.com/images/cups/hohesalve/EBCUP_Logo_Hohe_Salve_Hell_Licence_01-01.png"
                contain />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="3" md="4" lg="3" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3">
          <v-col cols="12" style="height: 100%">
            <v-row style="z-index: 1; position: relative;margin-top: -12px;">
              <v-col cols="5" class="pa-0" style="position: relative">
                <img class="info-bar-image" alt="E-Bike WM"
                     v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_02.jpg'">
                <h1 style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-style: italic; font-weight: 500;">
                  LICENSE
                </h1>
              </v-col>
              <v-col cols="7 pa-2" style="text-align: left;">
                <div>
                  <div style="height: 75px">
                    <div>
                      <strong>{{ $t('home.infoBar.license') }}</strong>
                    </div>
                    <div style="line-height: 1.2">
                      {{ $t('home.infoBar.licenseDescription') }}
                    </div>
                  </div>
                  <v-btn outlined color="primary" @click.stop="goToAnchor('#license')">
                    {{ $t("home.infoBar.news.discoverNow") }}
                    <v-icon>{{icons.right}}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <div class="outer-slider-wrapper" style="padding-left: 5px;" v-else>
      <div class="scrolling-wrapper-flexbox">
        <v-col class="home-info-bar-element info-bar-slider-item">
          <div class="home-info-bar-inner elevation-3">
            <img class="info-bar-background" alt="E-Bike WM"
                 v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_ebikewm2020_01.jpg'">
            <v-col cols="12">
              <v-row style="z-index: 1; position: relative;">
                <v-col cols="5" class="pa-0"/>
                <v-col cols="7" class="pa-0" style="text-align: left;">
                  <h4 style="color: white; font-weight: 600;line-height: 16px;margin-top: 5px;">{{ $t("home.infoBar.wm.title") }}</h4>
                  <div style="margin-bottom: 5px; color: white;">{{ $t("home.infoBar.wm.subtitle") }}</div>
                  <v-btn outlined color="primary" @click="openWM()">
                    {{ $t("home.infoBar.news.discoverNow") }}
                    <v-icon>{{icons.right}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col class="home-info-bar-element info-bar-slider-item">
          <div class="home-info-bar-inner elevation-3" @click="openHoheSalve()">
            <img class="info-bar-background" alt="E-Bike WM"
                 v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_01.jpg'">
            <v-row class="info-bar-title-container">
              <v-col cols="8" class="align-self-center">
                {{$t('home.infoBar.hoheSalve')}}
              </v-col>
              <v-col cols="4" class="text-center">
                <v-img
                  src="https://ebikecup.com/images/cups/hohesalve/EBCUP_Logo_Hohe_Salve_Hell_Licence_01-01.png"
                  contain />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="home-info-bar-element info-bar-slider-item">
          <v-row class="home-info-bar-inner elevation-3" style="margin-top: 0">
            <v-col cols="5" class="pa-0" style="position: relative">
              <img class="info-bar-image" alt="E-Bike WM"
                   v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_02.jpg'">
              <h1 style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-style: italic; font-weight: 500;">
                LICENSE
              </h1>
            </v-col>
            <v-col cols="7" class="pa-0" style="text-align: left;">
              <div style="padding: 14px;">
                <div>
                  <strong>{{ $t('home.infoBar.license') }}</strong>
                </div>
                <div style="line-height: 1.2">
                  {{ $t('home.infoBar.licenseDescription') }}
                </div>
                <v-btn outlined color="primary" @click.stop="goToAnchor('#license')">
                  {{ $t("home.infoBar.news.discoverNow") }}
                  <v-icon>{{icons.right}}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
  import {mdiChevronRight} from '@mdi/js'
  export default {
    name: 'info-bar',
    data() {
      return {
        icons: {
          right: mdiChevronRight,
        },
      };
    },
    methods: {
      goToAnchor(selector) {
        if (selector !== undefined && selector !== null) {
          this.globalEvents.$emit('goToElement', selector);
        }
      },
      openHoheSalve() {
        this.$router.push({ name: 'hohesalve' });
      },
      openWM() {
        window.open('https://ebikewm.com', '_blank');
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .home-info-bar-container {
    font-family: $default-font;
    position: relative;
    background-color: $basic-gray;
    @include MQ(M) {
      padding: 20px;
    }
    @include MQ(L) {
      padding: 20px;
    }
    @include MQ(XL) {
      padding: 20px;
    }
    .home-info-bar-element {
      padding: 10px;
      .home-info-bar-inner {
        height: 120px;
        position: relative;
        .info-bar-background {
          width: 100%;
          height: 120px;
          object-position: center;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          cursor: pointer;
        }

        .info-bar-title-container {
          position: absolute;
          cursor: pointer;
          left: 12px;
          top: 12px;
          height: 120px;
          font-size: 28px;
          color: white;
          font-family: $default-font;
          font-weight: 400;
          width: 100%;
          @include MQ(M) {
            font-size: 18px;
          }
          @include MQ(S) {
            font-size: 20px;
          }
          @include MQ(XS) {
            font-size: 20px;
          }
          img {
            cursor: pointer;
            height: 110px;
            margin-bottom: -10px;
            @include MQ(M) {
              height: 100%;
              margin-bottom: -10px;
            }
            @include MQ(S) {
              height: 100%;
              margin-bottom: -10px;
            }
            @include MQ(XS) {
              height: 100%;
              margin-bottom: -10px;
            }
          }
          .v-image {
            height: 90px;
          }
        }

        .info-bar-image {
          width: 100%;
          height: 120px;
          object-position: center;
          object-fit: cover;
          left: 0;
          top: 0;
        }
        .v-btn {
          border-radius: 15px;
          border-width: 2px;
          padding: 0 5px;
          height: 25px;
          text-transform: none;
          .v-btn__content {
            padding: 0;
            height: 23px;
          }
        }
        h3 {
          color: $primary;
          font-style: italic;
          line-height: 1.17em;
        }
      }
    }

    /********************** Infinite Slider (Mobile) **********************/
    .outer-slider-wrapper {
      height: 100%;
      width: 100%;
      overflow-y: hidden;

      .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;

        .info-bar-slider-item {
          min-width: 350px;
          margin-right: 10px;

          &.guiness-item {
            @include MQ(XS) {
              min-width: 300px;
            }
            @include MQ(S) {
              min-width: 330px;
            }
            @include MQ(M) {
              min-width: 330px;
            }
          }
        }

        ::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
        &::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
      }
    }
  }
</style>
