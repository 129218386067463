<template>
  <div class="home">
    <Header/>
    <InfoBar/>
    <v-container>
      <v-row class="mt-10 mb-10">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pt-0">
          <v-row>
            <v-col cols="12" id="about" class="pt-0">
              <h1>{{$t('home.about.title')}}</h1>
              <h1>{{$t('home.about.subtitle')}}</h1>
              <h2 class="primary-subtitle">{{ 2025 }}{{$t('home.about.underSubtitle')}}</h2>
            </v-col>
            <v-col cols="12" class="text-center">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph1')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pb-0">
              <p style="margin: 0;">
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph2')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph3')}}</span>
              </p>
            </v-col>
            <v-col cols="12">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph4')}}</span>
              </p>
            </v-col>
          </v-row>
          <About/>
        </v-col>
      </v-row>
    </v-container>
    <div class="home-gray">
      <v-container>
        <v-row class="mt-10 mb-10">
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
            <v-row id="events">
              <h1 style="margin-bottom: 25px;">{{$t('menu.events')}}</h1>
            </v-row>
            <Events/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Information/>
    <!-- <div class="home-gray">
      <Banner/>
    </div> -->
    <Shop/>
    <Partner/>
    <Media/>
    <div class="home-background-image-wm">
      <div class="wm-container">
        <h1>{{$t('home.wm.title')}}</h1>
        <h1 class="subtitle">{{$t('home.wm.subtitle')}}</h1>
        <p>{{$t('home.wm.paragraph1')}}</p>
        <p style="margin-bottom: 60px">{{$t('home.wm.paragraph2')}}</p>
        <v-btn color="secondary" @click="goToWM()">
          {{$t('home.wm.goToChampionship')}}
        </v-btn>
      </div>
    </div>
    <div class="home-gray">
      <News/>
    </div>
  </div>
</template>

<script>
import Header from "../components/home/Header";
import InfoBar from "../components/home/InfoBar";
import About from "../components/home/About";
const Events = () => import(/* webpackChunkName: "home-content" */ '../components/home/Events');
const Information = () => import(/* webpackChunkName: "home-content" */ '../components/home/Information');
// const Banner = () => import(/* webpackChunkName: "home-content" */ '../components/home/Banner');
const News = () => import(/* webpackChunkName: "home-content" */ '../components/home/News');
const Partner = () => import(/* webpackChunkName: "home-content" */ '../components/home/Partner');
const Shop = () => import(/* webpackChunkName: "home-content" */ '../components/Shop');
const Media = () => import(/* webpackChunkName: "home-content" */ '../components/home/Media');

export default {
  name: 'home',
  data() {
    return {
    };
  },
  components: {
    Header, InfoBar, Events, Information, News, Partner, About, Shop, Media,
  },
  mounted() {
    this.globalEvents.$on('goToElement', (selector) => {
      if (selector !== undefined) {
        this.$vuetify.goTo(selector, {
          duration: 100,
          offset: 70,
          easing: 'easeInOutCubic',
        });
      }
    });
  },
  methods: {
    goToWM() {
      window.open('https://ebikewm.com', '_blank');
    },
    goToCup() {
      window.open('https://ebikecup.com', '_blank');
    }
  },
}
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";

  .home {
    .home-gray {
      background-color: $basic-gray;
    }
    h1 {
      font-weight: 400;
      width: 100%;
    }
    .primary-subtitle {
      font-weight: 500;
      font-style: italic;
      color: $primary;
      text-transform: uppercase;
    }
    .text-image-container {
      h1 {
        text-align: left;
        font-style: italic;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      h4 {
        text-align: left;
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      p, li {
        text-align: left;
      }
      ul {
        list-style: none;
        li {
          &:before {
            content: "•";
            color: $primary;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      img {
        width: 100%;
        max-width: 500px;
        &.last {
          max-width: unset;
        }
      }
    }
    .hint {
      font-size: 12px;
      text-align: left;
      display: block;
      margin-top: 10px;
    }
    .home-background-image-wm {
      background-image: url("https://ebikecup.com/images/EBCUP_Teaserbild_Verlinkung_ebikewm_01.jpg");
      background-position: center;
      background-size: cover;
      height: 600px;
      position: relative;
      @include MQ(M) {
        height: 550px;
      }
      @include MQ(S) {
        height: 400px;
      }
      @include MQ(XS) {
        height: 350px;
      }
      .wm-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        h1 {
          color: white;
          font-weight: 600;
          font-style: italic;
          &.subtitle {
            color: $secondary;
          }
        }
        p {
          color: white;
          margin-bottom: 0;
        }
        .v-btn {
          text-transform: none;
          border-radius: 15px;
        }
      }
    }
  }
</style>
